
import {defineComponent} from "vue";
import {useUser} from "@/store/pinia/userStore";
import {MembershipRole} from "@/models/enums/Role";
import UserOnboarding from "@/components/Onboarding/UserOnboarding.vue";
import OnboardingStart from "@/components/Onboarding/OnboardingStart.vue";
import {useApplication} from "@/store/pinia/applicationStore";

export default defineComponent({
  components: {UserOnboarding, OnboardingStart},
  setup() {
    const applicationStore = useApplication()
    const userStore = useUser()
    return {userStore, applicationStore}
  },
  computed: {
    hasInvitations(): boolean {
      return this.userStore.invitations.length > 0
    },
    isOwner(): boolean {
      return this.userStore.productions.filter(p => p.role === MembershipRole.Owner).length > 0
    }
  }
})
