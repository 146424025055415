
import {defineComponent} from "vue";
import {useUser} from "@/store/pinia/userStore";
import {MembershipRole} from "@/models/enums/Role";
import Button from "@/components/UI/Button.vue";
import {OrganizationMembership} from "@/models/organizations/OrganizationMembership";
import {ProductionMembership} from "@/models/productions/ProductionMembership";
import {WebAnalytics} from "@/modules/webAnalytics";

export default defineComponent({
  components: {Button},
  setup() {
    const userStore = useUser()
    return {userStore}
  },
  methods: {
    navigateToUserHome() {
      this.$router.push({name: 'user-overview'})
      WebAnalytics.trackFlexible('Skipped Onboarding', {entityType: 'Start'})
    },
    navigateToUserOnboarding() {
      this.$router.push({
        name: 'user-onboarding',
        query: {fromFlow: 'true'}
      })
    },
    navigateToProductionOnboarding(productionId: string) {
      this.$router.push({
        name: 'production-onboarding',
        params: {productionId: productionId},
        query: {fromFlow: 'true'}
      })
    },
    navigateToOrganizationOnboarding(organizationId: string) {
      this.$router.push({
        name: 'organization-onboarding',
        params: {organizationId: organizationId},
        query: {fromFlow: 'true'}
      })
    },
  },
  computed: {
    hasInvitations(): boolean {
      return this.userStore.invitations.length > 0
    },
    isOwner(): boolean {
      return this.userStore.productions.filter(p => p.role === MembershipRole.Owner).length > 0
    },
    organizations(): Array<OrganizationMembership> {
      return this.userStore.organizations.filter(p => p.role === MembershipRole.Owner)
    },
    productions(): Array<ProductionMembership> {
      return this.userStore.productions.filter(p => p.role === MembershipRole.Owner)
    },
    userStatus(): string {
      return this.$route.query.userStatus as string || 'open'
    },
    orgStatus(): string {
      return this.$route.query.orgStatus as string || 'open'
    },
    prodStatus(): string {
      return this.$route.query.prodStatus as string || 'open'
    }
  }
})
